const standardPriceBuckets = [
	{
		precision: 2,
		min: 0,
		max: 3,
		increment: 0.01,
	},
	{
		precision: 2,
		max: 8,
		increment: 0.05,
	},
	{
		precision: 2,
		max: 20,
		increment: 0.5,
	},
	{
		precision: 2,
		max: 35,
		increment: 1.0,
	},
];

const videoPriceBuckets = [
	{
		precision: 2,
		max: 10,
		increment: 0.25,
	},
	{
		precision: 2,
		max: 25,
		increment: 0.5,
	},
	{
		precision: 2,
		max: 50,
		increment: 1,
	},
];
const TREATMENT_RATE = 0.95;
let liModuleEnabled = Math.random() < TREATMENT_RATE;

if (typeof window !== 'undefined') {
	// eslint-disable-next-line no-undef
	window.liTreatmentRate = TREATMENT_RATE;
	// eslint-disable-next-line no-undef
	if (typeof window.liModuleEnabled !== 'undefined') {
		// eslint-disable-next-line no-undef
		liModuleEnabled = window.liModuleEnabled;
	} else {
		// eslint-disable-next-line no-undef
		window.liModuleEnabled = liModuleEnabled;
	}
}
module.exports = {
	adrefresh: {
		enabled: true,
		minDispTimeDFP: 20000,
		minDispTimeADX: 30000,
		minSinceActive: 4000,
		maxInactive: 120000,
		minViewArea: 50,
		respectRoadblocks: true,
		minTimeHouse: 15000,
		minTimeADX: 30000,
		minTimeOX: 15000,
		minTimeDefault: 30000,
		houseAdvertiserIds: [5009920773],
		oxAdvertiserIds: [5024616868, 5022645737],
		adxAdvertiserIds: [5024496911],
		refreshSponsorships: false,
		sponsorshipLineIds: [
			6791813658, 6792573582, 6794156289, 6795812572, 6796418296, 6797490172, 6798544013, 6800189690, 6800569900, 6804682519,
			6804687826, 6805895987, 6806403960, 6809877524, 6813595090, 6813874444, 6818165909, 6818260465, 6824397359, 6825935960,
			6827927211, 6827992711, 6829229186, 6829229696, 6830090808, 6830095162, 6831381722, 6831441065, 6832126077, 6832330450,
			6832603414, 6832886341, 6832891151, 6833838890, 6834456451, 6835661492, 6837985421, 6838860940, 6840097384, 6840196998,
			6841280300, 6841290152, 6841294445, 6842597689, 6843333070, 6844577231, 6846521943, 6847783809, 6849017715, 6849095994,
			6849265413, 6849956623, 6850413292, 6851160650, 6851262665, 6851401669, 6851402140, 6852548861, 6852957970, 6854016689,
			6854049512, 6856367250, 6858783811, 6859498067, 6860562712, 6862126018, 6863761800, 6865575816, 6865873612, 6866027027,
			6866283979, 6869222388, 6869229813, 6871548686, 6871548695, 6873576709, 6873765746, 6874796369, 6884118362, 6886459570,
			6887608304,
		],
		noRefreshLineIds: [6704325809, 6704825445, 6707960777, 6745404447, 6745404459, 6747584806, 6784009433],
		noRefreshAdvertiserIds: [],
	},
	amazon: {
		pubID: 3158,
		enabled: true,
		adServer: 'googletag',
		regionConfig: {},
		allowedSizes: [
			[5, 5],
			[6, 6],
			[300, 250],
			[320, 50],
			[728, 90],
			[970, 250],
			[300, 600],
		],
	},
	analytics: {
		bidbarrel: [
			{
				id: 'rv-bidbarrel-core',
				protected: true,
				transport: 'beacon',
				frequency: 15000,
				session: { reportingPercentage: 100 },
				failThreshold: 3,
				url: '/event',
			},
		],
	},
	analyticsTimings: {
		utag: true,
		allowDuplicates: {},
		timeout: 60000,
		sequences: [
			['event-adlib-init', 'event-adlib-first-auction'],
			['event-adsrvr-first-req', 'event-first-render', 'event-first-imp'],
		],
		scripts: {
			'script-gpt': {
				matchPart: 'securepubads.g.doubleclick.net/tag/js/gpt.js',
				record: {
					slug: 'script-gpt',
					name: 'Time to GPT',
				},
			},
			'script-pubads': {
				matchPart: 'pubads_impl.js',
				record: {
					slug: 'script-pubads',
					name: 'Time to pubads_impl',
				},
			},
			'script-adlib': {
				matchPart: '(bidbarrel-[A-z]*(-rv)?)+(.*)(.min)?(.js)',
				record: {
					slug: 'script-adlib',
					name: 'Time to Ad Library',
				},
			},
			'scripts-cbsoptanon': {
				matchPart: '/a/privacy/optanon/optanon-',
				record: {
					slug: 'script-cbsoptanon',
					name: 'Time to CMP',
				},
			},
			'scripts-onetrust': {
				matchPart: 'otSDKStub.js',
				record: {
					slug: 'script-onetrust',
					name: 'Time to OneTrust',
				},
			},
			'scripts-cohesion': {
				matchPart: '(cohesion-[A-z]*)+(.min)?(.js)',
				record: {
					slug: 'script-cohesion',
					name: 'Time to Cohesion',
				},
			},
			// 'scripts-pubhub': {
			// 	matchPart: 'pwt.js',
			// 	record: {
			// 		slug: 'script-pubhub',
			// 		name: 'Time to PubHub',
			// 	},
			// },
			'scripts-intentiq': {
				matchPart: 'IIQUniversalID.js',
				record: {
					slug: 'script-intentiq',
					name: 'Time to IntentIQ',
				},
			},
		},
		events: {
			initialize: {
				once: true,
				record: {
					slug: 'event-adlib-init',
					name: 'Time to Ad Library Initialize',
				},
			},
			auction: {
				once: true,
				record: {
					slug: 'event-adlib-first-auction',
					name: 'Time to First Ad Library Auction',
				},
			},
			requested: {
				once: true,
				event: {
					gpt: true,
					name: 'slotRequested',
				},
				record: {
					slug: 'event-adsrvr-first-req',
					name: 'Time to First Ad Server Request',
				},
			},
			rendered: {
				once: true,
				event: {
					gpt: true,
					name: 'slotRenderEnded',
				},
				record: {
					slug: 'event-first-render',
					name: 'Time to First Ad Render',
				},
			},
			viewable: {
				once: true,
				event: {
					gpt: true,
					name: 'impressionViewable',
				},
				record: {
					slug: 'event-first-imp',
					name: 'Time to First Viewable Impression',
				},
			},
      // adDensityAvailable: {
      //   once: true,
      //   record: {
			// 		slug: 'ad-density',
			// 		name: 'Time to Ad Density available',
			// 	},
      // },
			enrichmentFired: {
				once: true,
				record: {
					slug: 'event-enrichment-fired',
					name: 'Time to Enrichment request fired',
				},
			},
			enrichmentReturned: {
				once: true,
				record: {
					slug: 'event-enrichment-returned',
					name: 'Time to Enrichment request returned',
				},
			},
			// pubhubLoaded: {
			// 	once: true,
			// 	record: {
			// 		slug: 'event-pubhub-loaded',
			// 		name: 'Time to PubHub file loaded',
			// 	},
			// },
			// pubhubReady: {
			// 	once: true,
			// 	record: {
			// 		slug: 'event-pubhub-ready',
			// 		name: 'Time to PubHub IDs added',
			// 	},
			// },
			intentiqLoaded: {
				once: true,
				record: {
					slug: 'event-intentiq-loaded',
					name: 'Time to IntentIQ file loaded',
				},
			},
			intentiqReady: {
				once: true,
				record: {
					slug: 'event-intentiq-ready',
					name: 'Time to IntentIQ IDs added',
				},
			},
			consentReady: {
				once: true,
				record: {
					slug: 'event-consent-ready',
					name: 'Time to consent ready',
				},
			},
			tagularReady: {
				once: true,
				record: {
					slug: 'event-tagular-ready',
					name: 'Time to Tagular Ready Fired',
				},
			},
		},
		// For Bidders if we'd like to measure their requests: https://github.com/prebid/header-bidder-expert/blob/master/src/js/definitions/calls.js
	},
  api: {
		baseUrl: 'https://www.cnet.com/irb/lib/api',
    clientInfoUrl: 'https://www.cnet.com/irb/client-info',
		apiBase: {
			prod: 'https://www.cnet.com/irb/lib/api',
			stage: 'https://test.cnet.com/irb/lib/api',
		},
		version: 1,
		fetchOptions: {
			useVersion: true,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		},
	},
	autoPriority: {
		enabled: true,
		refresh: true,
		bids: true,
	},
	blockthrough: {
		enabled: false,
		scriptUrl: 'https://btloader.com/tag?o=6209001497821184&upapi=true',
	},
	bootstrap: {
		renderScripts: [],
	},
	confiant: {
		badAdRefresh: true,
	},
	cookie: {
		defaultOptions: {
			path: '/',
		},
		dailySession: {
			cookieName: '_BB.d',
			defaultOptions: {
				expires: 'midnightEst',
			},
			deserialize: {
				match: (val) => val && val.indexOf('|') >= 0,
				process: (val) => {
					if (!val) return { firstpg: undefined, ftag: undefined, ttag: undefined, pv: undefined };
					const [firstpg, ftag, ttag, pv] = val.split('|');
					// eslint-disable-next-line no-self-compare
					const adjustedPv = parseInt(pv, 10) === parseInt(pv, 10) ? parseInt(pv, 10) : pv;
					return { firstpg, ftag, ttag, pv: adjustedPv };
				},
			},
			serialize: {
				match: (val) => !!val,
				process: ({ firstpg, ftag, ttag, pv }) => [firstpg, ftag, ttag, pv].join('|').replace(/undefined/gm, ''),
			},
		},
		browserSession: {
			cookieName: '_BB.bs',
			deserialize: {
				match: (val) => val && val.indexOf('|') >= 0,
				process: (val) => {
					if (!val) return { session: undefined, subses: undefined };
					const [session, subses] = val.split('|');
					return { session, subses };
				},
			},
			serialize: {
				match: (val) => !!val,
				process: ({ session, subses }) => [session, subses].join('|').replace(/undefined/gm, ''),
			},
		},
		enrichment: {
			cookieName: '_BB.enr',
			defaultOptions: {
				expires: 'midnightEst',
			},
		},
		gaClientId: {
			cookieName: '_ga',
			readOnly: true,
		},
		aamUuid: {
			cookieName: 'aam_uuid',
			readOnly: true,
		},
		seg: {
			cookieName: 'aamgam',
			readOnly: true,
			deserialize: {
				match: (val) => !!val,
				process: (val) => decodeURI(val).replace('segid=', '').split(','),
			},
			serialize: {
				match: (val) => !!val,
				process: (val) => encodeURI(`segid=${val.join(',')}`),
			},
		},
	},
	debug: {
		consoleMethods: {
			error: true,
			warn: true,
		},
	},
	dfpPath: {
		region: 'aw',
		network: '22309610186',
		device: '',
	},
	dfpPathDevices: {
		desktop: '',
		web: '',
		mweb: 'm',
		mobile: 'm',
		app: 'app',
		m: 'm',
		application: 'app',
	},
	dynamicTargeting: {
		rules: [
			{
				name: 'Slot Name String',
				matcher: () => true, // (unit) => !unit.isVideo || !unit.allowedTypes.video,
				key: 'sl',
				level: 'unit',
				hook: 'processAdRequest',
				getValue: (unit, context) => {
					const slFlags = {
						LL: unit.isLazyLoaded(),
					};
					const slResultArr = [];
					// eslint-disable-next-line no-restricted-syntax
					for (const targetingValue in slFlags) {
						if (Object.prototype.hasOwnProperty.call(slFlags, targetingValue)) {
							const shouldInclude = slFlags[targetingValue];
							if (shouldInclude) {
								slResultArr.push(targetingValue);
							}
						}
					}

					if (context.bidderTimeout) {
						slResultArr.push(`T-${context.bidderTimeout}`);
					}

					if (typeof context.seamlessIndicator !== 'undefined') {
						slResultArr.push(`SI-${context.seamlessIndicator}`);
					}

					if (unit.isVideo || unit.allowedTypes.video) {
						// eslint-disable-next-line prefer-template
						return unit.originalCode + (slResultArr.length > 0 ? '?' + slResultArr.join('%7C') : '');
					}
					// eslint-disable-next-line prefer-template
					return unit.originalCode + (slResultArr.length > 0 ? '?' + slResultArr.join('|') : '');
				},
			},
			{
				name: 'Slim Slot Name String', // slot name before the ? so that DoubleVerify can use it
				matcher: () => true,
				key: 'sldv',
				level: 'unit',
				hook: 'processAdRequest',
				getValue: (unit) => unit.originalCode,
			},
			{
				name: 'Adobe Segment IDs',
				matcher: () => true,
				key: 'seg',
				level: 'page',
				hook: 'processAdRequest',
				cacheResult: true,
				getValue: (context) => context.getCookie('seg') || null,
			},
			{
				name: 'Adobe Audience Manager ID',
				matcher: () => true,
				key: 'aamid',
				level: 'page',
				hook: 'processAdRequest',
				cacheResult: true,
				getValue: (context) => context.getCookie('aamUuid') || null,
			},
			{
				name: 'Impression ID',
				matcher: () => true,
				key: 'iid',
				level: 'unit',
				hook: 'setBidTargeting',
				// eslint-disable-next-line no-unused-vars
				getValue: (unit, context) => `unit=${unit.code}|${unit.generateImpressionId(['vguid', 'pv', 'hb_adid', 'amzniid'])}`,
			},
			{
				name: 'Live Intent Module Indicator',
				matcher: () => true,
				key: 'li-module-enabled',
				level: 'page',
				hook: 'processAdRequest',
				cacheResult: false,
				getValue: (context) => ((context.property === 'cnet' || context.property === 'zdnet') && liModuleEnabled ? ['on'] : ['off']),
			},
		],
		units: {},
	},
   // A reminder: Changes in dynamicBidders will require a numbered deploy.  These will NOT go live with a remote config deploy.
	dynamicBidders: {
		/* eslint-disable no-unused-vars */
		appnexus: (existingBidderParamsArgs, { isVideo, getLatestVideoSpec }) => {
			let existingBidderParams = existingBidderParamsArgs;
			if (isVideo) {
				const video = getLatestVideoSpec();
				existingBidderParams = {
					...existingBidderParams,
					video: {
						mimes: video.mimes,
						minduration: video.minduration,
						maxduration: video.maxduration,
						startdelay: video.startdelay,
						skippable: video.skip === 1,
						playback_method: ['', 'auto_play_sound_on', 'auto_play_sound_off', 'click_to_play'].filter((v, i) => video.playbackmethod.indexOf(i) >= 0),
					},
				};
			}
			return existingBidderParams;
		},
		appnexusAst: (existingBidderParamsArgs, { isVideo, getLatestVideoSpec }) => {
			let existingBidderParams = existingBidderParamsArgs;
			if (isVideo) {
				const video = getLatestVideoSpec();
				existingBidderParams = {
					...existingBidderParams,
					video: {
						mimes: video.mimes,
						minduration: video.minduration,
						maxduration: video.maxduration,
						startdelay: video.startdelay,
						skippable: video.skip === 1,
						playback_method: ['', 'auto_play_sound_on', 'auto_play_sound_off', 'click_to_play'].filter((v, i) => video.playbackmethod.indexOf(i) >= 0),
					},
				};
			}
			return existingBidderParams;
		},
		criteo: (existingBidderParams, unitConfig) => ({ networkId: '2714', pubid: '5331_E4CMGJ', ...existingBidderParams }),
		grid: (existingBidderParams, unitConfig, context) => {
			const { extraParams } = context.bidderContext;
			return {
				...existingBidderParams,
				...extraParams,
			};
		},
		ix: (existingBidderParamsArgs, unitConfig) => {
			const existingBidderParams = existingBidderParamsArgs;
			if (unitConfig.isVideo || unitConfig.allowedTypes.video) {
				existingBidderParams.video = unitConfig.getLatestVideoSpec();
				return existingBidderParams;
			}
			if (!existingBidderParams.size) return null;
			const includesSize =
				unitConfig
					.getSizes()
					.map((s) => (Array.isArray(s) ? s.join('x') : s))
					.indexOf(existingBidderParams.size.join('x')) >= 0;
			return includesSize ? existingBidderParams : null;
		},
		medianet: (existingBidderParams, unitConfig) => ({ cid: '8CU391FYK', ...existingBidderParams }),
		// eslint-disable-next-line no-unused-vars
		nobid: (existingBidderParams, unitConfig) => {
			if (!existingBidderParams.included) return null;
			return { siteId: '68361', ...existingBidderParams };
		},
		pubmatic: (existingBidderParamsArgs, unitConfig) => {
			let existingBidderParams = existingBidderParamsArgs;
			if (!existingBidderParams.included) return null;
			if (unitConfig.isVideo || unitConfig.allowedTypes.video) {
				existingBidderParams = {
					...existingBidderParams,
					publisherId: '156082',
					adSlot: [
						// eslint-disable-next-line no-undef
						window.BidBarrel.dfpPathObj.property,
						unitConfig.originalCode || unitConfig.code,
					].join('_'),
					video: {
						plcmt: 1,
						placement: 1,
					},
				};
				return existingBidderParams;
			}
			const sizes = unitConfig.getSizes();
			const compatibleSizes = ['300x250', '320x50', '728x90', '970x250', '300x600'];
			const isCompatibleSize = (size) => (typeof size === 'string' ? false : compatibleSizes.indexOf(size.join('x')) >= 0);
			const processedSizes = sizes.filter(isCompatibleSize);
			return processedSizes.length <= 0
				? null
				: processedSizes.map((size) => ({
						publisherId: '156082',
						adSlot: [
							// eslint-disable-next-line no-undef
							window.BidBarrel.dfpPathObj.property,
							unitConfig.originalCode || unitConfig.code,
							size.join('x'),
						].join('_'),
					}));
		},
		rise: (existingBidderParams, unitConfig) => {
			if (!existingBidderParams.included) return null;
			return { org: '642c4ef8ef16c40001db86d9', ...existingBidderParams };
		},
		spotx: (existingBidderParamsArgs, { isVideo, getSizes }) => {
			let existingBidderParams = existingBidderParamsArgs;
			if (isVideo) {
				//   const sizes = getSizes();
				//   const widths = sizes.map(v => v[0]);
				//   const heights = sizes.map(v => v[1]);
				//   const max_height = Math.max(...heights);
				//   const max_width = Math.max(...widths);
				//   const min_height = Math.min(...heights);
				//   const min_width = Math.min(...widths);
				existingBidderParams = {
					...existingBidderParams,
					price_floor: 1,
					//   ext: {
					//     max_width,
					//     max_height,
					//     min_height,
					//     min_width
					//   }
				};
			}
			return existingBidderParams;
		},
		// eslint-disable-next-line no-unused-vars
		ttd: (existingBidderParamsArgs, unitConfig) => {
			const existingBidderParams = existingBidderParamsArgs;
			if (!existingBidderParams.included) return null;
			return { supplySourceId: 'cnet', publisherId: '1', ...existingBidderParams };
		},
		teads: (value) => {
			if (!value.placementId || !value.pageId) return null;
			return {
				pageId: value.pageId ? parseInt(value.pageId, 10) : '',
				placementId: value.placementId ? parseInt(value.placementId, 10) : '',
			};
		},
		trustx: (existingBidderParams, unitConfig, context) => {
			const { extraParams } = context.bidderContext;
			return {
				...existingBidderParams,
				...extraParams,
			};
		},
		yahooAds: (existingBidderParams, unitConfig) => ({ network: '58918', ...existingBidderParams }),
		yieldmo: (existingBidderParams, unitConfig, context) => ({ placementId: `${existingBidderParams.placementId}` }),
		/* eslint-enable no-unused-vars */

		// sonobi: (existingBidderParams, unitConfig, context) => {
		// 	if (!existingBidderParams.included || unitConfig.isVideo || unitConfig.allowedTypes.video) return null;
		// 	const { network, device, region, property } = context.dfpPath;
		// 	return {
		// 		dom_id: unitConfig.code,
		// 		// eslint-disable-next-line prefer-template
		// 		ad_unit: `/${network}/${device}${region}-${property}/${unitConfig.originalCode}` + (unitConfig.incremental ? unitConfig.index : ''),
		// 	};
		// },
		// openx: (existingBidderParams, unitConfig) => {
		// 	if (!existingBidderParams.delDomain) {
		// 		return {
		// 			delDomain: 'cnet-d.openx.net',
		// 			...existingBidderParams,
		// 		};
		// 	}
		// 	return existingBidderParams;
		// },
	},
	enrichment: {
		token: '6e4d8710-04aa-4aba-8ea0-6436ce2e14c6',
		endpoint: {
			// ...ruleset/{sourceId}/{rulesetId}
			prod: 'https://monarch.cohesionapps.com/api/v1/evaluate/ruleset/6c8c3ead-bc7a-4fe6-98e6-532258665aee/57ab7954-a37c-4779-9e02-b364f56ce84c',
			dev: 'https://monarch.cohesionapps.com/api/v1/evaluate/ruleset/6c8c3ead-bc7a-4fe6-98e6-532258665aee/c2fcd245-46d1-4784-843e-7ffdeca5a0d9',
		},
		enrichPrebid: true,
	},
	errorReporting: {
		chunkSize: 20,
		reportInterval: 15000,
		reportingPercentage: 1,
	},
	geo: {
		enabled: true,
		failsafeRegionCode: 'us',
		failsafeCountryCode: 'us',
		failsafeTimeout: 2000,
		// eslint-disable-next-line global-require
		regionDefinitions: require('./region-definitions.json'),
	},
	highFrequencyAdRequests: false,
	ias: {
		publisherOptimization: {
			scriptUrl: 'https://cdn.adsafeprotected.com/iasPET.1.js',
		},
	},
	// eslint-disable-next-line global-require
	identity: require('./identity.json'),
	lazyLoading: {
		auction: {
			enabled: false,
			chunkSize: 15,
			chunk: true,
			lazy: false,
			offset: {
				yAxis: 1000,
				xAxis: 1600,
			},
			percentageVisible: 1,
		},
		units: {
			offset: {
				yAxis: 400,
				xAxis: 800,
			},
			percentageVisible: 1,
		},
	},
	dv: {
		enabled: true,
		scriptUrl: 'https://pub.doubleverify.com/dvtag/33509408/DV1307377/pub.js',
	},
	// pubhub: {
	// 	enabled: false,
	// 	scriptLoc: 'https://ads.pubmatic.com/AdServer/js/pwt/160651/5606',
	// 	scriptFile: '/pwt.js',
	// },
	intentIQ: {
		enabled: false,
		scriptUrl: 'https://www.cnet.com/irb/lib/dist/main/IIQUniversalID.js',
		params: {
			partner: 431700236,
			timeoutInMillis: 400,
			ABTestingConfigurationSource: 'percentage',
			abPercentage: 95,
			manualWinReportEnabled: true,
			vrBrowserBlackList: ['chrome'],
		},
	},
	prebid: {
		enabledRegions: ['us', 'emea', 'apac'],
		cacheUrlConfigs: {
			prefix: 'bb_vcache_',
			bidders: {
				default: {
					// url: "prebid.adnxs.com/pbc/v1/cache",
					// key: "uuid"
				},
			},
			// default: "https://dev.at.cbsi.com/lib/api/v1/vcache?uuid="
			// spotx: "https://search.spotxchange.com/ad/vast.html?key="
		},
		ids: {
			pubCommon: {
				enabled: true,
				autoDeleteStorage: false,
				userSyncConfig: {
					name: 'pubCommonId',
					storage: {
						type: 'cookie',
						name: '_pubcid',
						expires: 365,
					},
				},
			},
			identityLink: {
				enabled: false,
				template: true,
				trackChanges: true,
				autoDeleteStorage: false,
				shouldRefresh: (oldConfig, newConfig) => {
					if (!newConfig || !newConfig.params) return false;
					if ((!oldConfig && newConfig) || (!oldConfig.params && newConfig.params)) return true;
					return (oldConfig.params.eid === '' && newConfig.params.eid !== '') || (newConfig.params.eid !== '' && newConfig.params.eid !== oldConfig.params.eid);
				},
				dependencies: "<%= context.getValue('config.dfpPath') %>",
				onRemove: {
					cookies: ['_lr_retry_request'],
				},
				userSyncConfig: {
					name: 'identityLink',
					params: {
						eid: "<%= context.getValue('config.firstPartyData.eid') %>",
					},
					storage: {
						type: 'cookie',
						name: '_BB.id.identityLink',
						expires: 15,
					},
				},
			},
			liveIntent: {
				enabled: liModuleEnabled,
				trackChanges: true,
				autoDeleteStorage: false,
				userSyncConfig: {
					name: 'liveIntentId',
					params: {
						liCollectConfig: {
							appId: 'a-0168',
						},
						requestedAttributesOverrides: {
							uid2: true,
							bidswitch: true,
							medianet: true,
							magnite: true,
							pubmatic: true,
							index: true,
						},
						partner: 'prebid',
					},
					storage: {
						type: 'cookie',
						name: '_BB.id.liveIntent',
						expires: 1,
					},
				},
			},
			criteo: {
				enabled: false,
				autoDeleteStorage: false,
				userSyncConfig: {
					name: 'criteo',
				},
			},
			unifiedId: {
				enabled: false,
				userSyncConfig: {
					name: 'unifiedId',
					params: {
						partner: 'iiw3hzm',
					},
				},
			},
		},
		allowedSizes: [
			[5, 5],
			[6, 6],
			[300, 250],
			[320, 50],
			[728, 90],
			[640, 480],
			[970, 250],
			[300, 600],
			[160, 600],
			[300, 251],
			[11, 11],
		],
		priceBuckets: standardPriceBuckets,
		pbjsConfig: {
			enableTIDs: true,
			allowActivities: {
				transmitTid: {
					rules: [{ allow: true }],
				},
			},
			transmitTid: true,
			bidderSettings: {
				standard: {
					storageAllowed: true,
				},
				criteo: {
					storageAllowed: true,
				},
			},
			gptPreAuction: {
				enabled: true,
				useDefaultPreAuction: false,
				customPreAuction(adUnitCode, adServerAdSlotArg) {
					let adServerAdSlot = adServerAdSlotArg;
					if (typeof adServerAdSlotArg === 'undefined') {
						// eslint-disable-next-line no-undef
						adServerAdSlot = window.BidBarrel.dfpPathObj.string;
					}
					return `${adServerAdSlot}#${adUnitCode.code}`;
				},
			},
			userSync: {
				syncEnabled: true,
				iframeEnabled: true,
				auctionDelay: 300,
				syncsPerBidder: 8,
				syncDelay: 3000,
				filterSettings: {
					iframe: {
						bidders: '*',
						filter: 'include',
					},
					image: {
						bidders: '*',
						filter: 'include',
					},
				},
			},
			priceGranularity: {
				buckets: standardPriceBuckets,
			},
			mediaTypePriceGranularity: {
				video: { buckets: videoPriceBuckets },
			},
			enableSendAllBids: false,
			floors: {
				enabled: false,
				data: {
					currency: 'USD',
					skipRate: 0,
				},
				additionalSchemaFields: {
					fold: (bidRequest) => {
						// video units have a -- appended to the adUnitCode
						const adUnitCodeMatch = bidRequest?.adUnitCode?.match(/(.*)--/);
						const adUnitCode = adUnitCodeMatch ? adUnitCodeMatch[1] : bidRequest?.adUnitCode;
						const pos = $$BB_VAR$$?.adUnitsObj?.[adUnitCode]?.targeting?.pos ?? '';
						if (pos === 'sticky' || pos === 'top' || pos === 'nav') {
							return 'atf';
						}
						return 'btf';
					},
					topAdUnit: (bidRequest) => {
						let topAdUnit = '';
						const adSlot = bidRequest?.ortb2Imp?.ext?.data?.adserver?.adslot;
						if (adSlot !== undefined) {
							// eslint-disable-next-line no-useless-escape
							topAdUnit = /(\/[0-9]*\/[^\/]*)/g.exec(adSlot)?.[0] ?? undefined;
						}
						return topAdUnit;
					},
				},
			},
			s2sConfig: {
				accountId: '12849',
				enabled: true,
				defaultVendor: 'appnexuspsp',
				allowUnknownBidderCodes: true,
				extPrebid: { targeting: { includebidderkeys: true, includewinners: true } },
				bidders: ['appnexus'],
				timeout: 1000,
				adapter: 'prebidServer',
				endpoint: {
					p1Consent: 'https://ib.adnxs.com/openrtb2/prebid',
					noP1Consent: 'https://ib.adnxs-simple.com/openrtb2/prebid',
				},
			},
			targetingControls: {
				alwaysIncludeDeals: true,
			},
			cache: {
				url: 'https://prebid.adnxs.com/pbc/v1/cache',
			},
		},
	},
	priorityAxis: 'y',
	rvconsent: {
		type: 'onetrust',
		timeout: 2000,
		setNpaOnConsentChange: true,
		enableServices: false,
		renderScript: true,
		scriptUrls: {
			dev: 'https://production-cmp.isgprivacy.cbsi.com/dist/optanon-v1.1.0.js',
			prod: 'https://production-cmp.isgprivacy.cbsi.com/dist/optanon-v1.1.0.js',
		},
	},
	targeting: {
		auto: false,
		query: {
			keyMap: {},
		},
		seats: {
			session: 6,
			subsession: 4,
		},
		cookie: {
			expiration: null,
			path: '/',
			domain: null,
			samesite: 'None',
			consolidate: false,
			keyMap: {
				ftag: 'ftag',
				ttag: 'ttag',
				firstpg: 'first_page_today',
			},
			getters: {},
			setters: {},
			deleters: {},
		},
		track3pc: {
			enabled: true,
			url: 'https://irb.cnetstatic.com/lib/dist/main/cookietest.html',
		},
	},
	timeouts: {
		failsafe: 3000,
		bidder: 1000,
		hfar: 700,
	},
	video: {
		detectCapabilities: true,
		spec: {
			// refer to https://www.iab.com/wp-content/uploads/2016/03/OpenRTB-API-Specification-Version-2-5-FINAL.pdf under video section for keys / values
			mimes: ['video/mp4', 'video/H264', 'video/webm'],
			startdelay: 0,
			w: 640,
			h: 480,
			skip: 0,
			minduration: 5,
			maxduration: 30,
			context: 'instream',
			api: [1, 2],
			linearity: 1,
			protocols: [1, 2, 3, 4, 5, 6, 7, 8],
			playbackmethod: [2],
			playbackend: 1,
			minbitrate: 360,
			maxbitrate: 2500,
			plcmt: 2,
			placement: 4,
			vpmute: 2,
		},
		getSpec: (existingSpec, { PLAYBACK_METHOD }, context) => {
			const modifiedValue = existingSpec;
			// Exclude vpaid on mobile
			if (context.isMobile()) {
				modifiedValue.api = modifiedValue.api.filter((v) => [1, 2].indexOf(v) === -1);
			}
			// When VPAID enabled exclude video/h264 or video/webm mimes
			if (modifiedValue.api.indexOf(1) >= 0 || modifiedValue.api.indexOf(2) >= 0) {
				modifiedValue.mimes = modifiedValue.mimes.filter((v) => ['video/H264', 'video/webm'].indexOf(v) === -1);
			}

			if (context.canAutoplayUnmuted) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.PAGELOAD_SOUND_ON, PLAYBACK_METHOD.PAGELOAD_SOUND_OFF];
			} else if (context.canAutoplayMuted) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.PAGELOAD_SOUND_OFF];
			} else {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.CLICK_SOUND_ON];
			}

			return modifiedValue;
		},
		// eslint-disable-next-line no-unused-vars
		getUnitSpec: (existingSpec, unitConfig, { PLAYBACK_METHOD }) => ({ sequence: unitConfig.sequenceIndex, ...existingSpec }),
		translateSpec: (existingSpec, unitConfig, { PLAYBACK_METHOD }, context) => {
			const modifiedValue = existingSpec;
			let requestedFunctionality;
			if (modifiedValue.h === '480v') {
				requestedFunctionality = PLAYBACK_METHOD.PAGELOAD_SOUND_ON;
			} else if (modifiedValue.h === '483v') {
				requestedFunctionality = PLAYBACK_METHOD.PAGELOAD_SOUND_OFF;
			} else if (modifiedValue.playerSize && modifiedValue.playerSize.length === 2 && modifiedValue.playerSize[1] === '480v') {
				requestedFunctionality = PLAYBACK_METHOD.PAGELOAD_SOUND_ON;
			} else if (modifiedValue.playerSize && modifiedValue.playerSize.length === 2 && modifiedValue.playerSize[1] === '483v') {
				requestedFunctionality = PLAYBACK_METHOD.PAGELOAD_SOUND_OFF;
			}
			if (requestedFunctionality === PLAYBACK_METHOD.PAGELOAD_SOUND_OFF && context.canAutoplayMuted) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.PAGELOAD_SOUND_OFF];
			} else if (requestedFunctionality === PLAYBACK_METHOD.PAGELOAD_SOUND_ON && context.canAutoplayUnmuted) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.PAGELOAD_SOUND_ON, PLAYBACK_METHOD.PAGELOAD_SOUND_OFF];
			} else if (requestedFunctionality) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.CLICK_SOUND_ON];
			}

			if (requestedFunctionality) {
				modifiedValue.h = 480;
			}
			if ((!modifiedValue.h || !modifiedValue.w) && modifiedValue.playerSize && modifiedValue.playerSize.length === 2) {
				if (!modifiedValue.w) {
					// eslint-disable-next-line prefer-destructuring
					modifiedValue.w = modifiedValue.playerSize[0];
				}
				if (!modifiedValue.h) {
					// eslint-disable-next-line prefer-destructuring
					modifiedValue.h = modifiedValue.playerSize[1];
				}
			} else if (!existingSpec.playerSize || modifiedValue.playerSize.length !== 2) {
				modifiedValue.playerSize = [modifiedValue.w, modifiedValue.h];
			}

			return modifiedValue;
		},
		defaultParamOptions: {
			// eslint-disable-next-line no-unused-vars
			getParams: (unitConfig, index) => ({}),
			params: {
        plcmt: 2,
      },
			targeting: {
				unit: false,
				page: false,
				allowlist: [
          'session', 'subses', 'subsession', 'pv', 'vguid', 'abtest', 'ftag', 'useg',
          'cid', 'collection', 'tag', 'topic', 'ptopic', 'ptype', 'ctopic', 'entity', 'subcollection',
          'bsc', 'ids', 'tvp', 'vlp', // DV related
          'vid', 'mfr', 'contentType', 'device'
        ],
			},
		},
		getDefaultParamOptions: (currentOptions) => currentOptions,
		adServerUrl: 'https://pubads.g.doubleclick.net/gampad/ads',
	},
};
